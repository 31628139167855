import './IngredientDropDown.css';

import React, { useState, useRef, useEffect, Fragment } from "react";


function IngredientDropDown() {
  const [ value, setValue ] = useState("");
  const [ showDropdown, setShowDropdown ] = useState(false);
  const wrapperRef = useRef(null);
  const suggestions = ["apple", "banana"];
  const suggestionArr = suggestions
    .filter(suggestion =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
  const changeHandler = e => {
    setValue(e.target.value);
  };

  const handleClickOutside = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  // Attaching the previous event with UseEffect hook
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  console.log(suggestionArr);

  return (
    <div className="input__wrapper" ref={wrapperRef}>
      <input
        type="text"
        onChange={changeHandler}
        onFocus={() => setShowDropdown(true)}
        value={value}
      />
      {showDropdown && (
        <div className="suggestions__dropdown">
          {suggestionArr && suggestionArr.length > 0 ? (
            <Fragment>
              {suggestionArr.map((suggestion, index) => (
                <div
                  key={"suggestion_" + index}
                  className="suggestion__item"
                  onClick={() => setValue(suggestion)}>
                    {suggestion}
                </div>
              ))}
            </Fragment>
          ) : (
            <div className="no__suggestions">No suggestions found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default IngredientDropDown;