import './Home.css';
import { Navigate } from "react-router-dom";

function Home() {
    return (
        <Navigate to="/recipes" />
    );
}

export default Home;
