import './ConfirmModal.css'

const ConfirmModal = ({text, submit, close}) => {
    
    const handleSubmit = () => {

        let error = submit();
        
        if (error == null) {
            close();
        } else {
            alert(error);
        }
    }

    return (
        <div className='confirm-modal'>
            <div>
                <p>{text}</p>
                <div className="button-panel">
                    <button onClick={handleSubmit} type="submit">Yes</button>
                    <button onClick={() => close()}>No</button>          
                </div>
                
            </div>
        </div>
    )
};

export default ConfirmModal;


