/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get user info. This call can be used to confirm login credentials  alongside the basic authentication scheme.
     *
     * @returns any Authenticated
     * @throws ApiError
     */
    public static headApiUser(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'HEAD',
            url: '/api/user',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Change username and password
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static putApiUser(
        requestBody: {
            /**
             * New username for the service
             */
            username?: string;
            /**
             * New password for the service
             */
            password?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request which can be due to invalid body`,
                401: `Unauthorized`,
            },
        });
    }

}
