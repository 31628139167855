/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ingredient } from '../models/Ingredient';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IngredientService {

    /**
     * Create a new ingredient
     * @param requestBody Ingredient to be updated
     * @returns any Ingredient successfully created
     * @throws ApiError
     */
    public static postApiIngredient(
        requestBody: Ingredient,
    ): CancelablePromise<{
        /**
         * ID the newly created ingredient
         */
        id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ingredient',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Returns all ingredients
     * @returns any An array of all available ingredients
     * @throws ApiError
     */
    public static getApiIngredientAll(): CancelablePromise<Array<{
        /**
         * unique id of the given ingredient
         */
        id?: number;
        ingredient?: Ingredient;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ingredient/all',
        });
    }

    /**
     * Returns ingredients which match with the query string
     * @param q Query string
     * @returns any An array of matching ingredients
     * @throws ApiError
     */
    public static getApiIngredientSearch(
        q: string,
    ): CancelablePromise<Array<{
        /**
         * unique id of the given ingredient
         */
        id?: number;
        unit?: Ingredient;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ingredient/search',
            query: {
                'q': q,
            },
        });
    }

    /**
     * Update an ingredient
     * @param id ingredient id
     * @param requestBody Ingredient to be updated
     * @returns any Ingredient successfully updated
     * @throws ApiError
     */
    public static putApiIngredient(
        id: number,
        requestBody: Ingredient,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ingredient/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete an ingredient
     * @param id ingredient id
     * @returns any Ingredient successfully deleted
     * @throws ApiError
     */
    public static deleteApiIngredient(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ingredient/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
