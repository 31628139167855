import './IngredientModal.css'

import { useState } from 'react';
import { useUnits } from '../scripts/hooks';

const IngredientModal = ({ confirmText, submit, close, defaults }) => {
    const units = useUnits(0);

    const [name, setName] = useState(defaults?.name ?? "");
    const [search, setSearch] = useState(defaults?.search ?? "");
    const [density, setDensity] = useState(defaults?.density ?? "");
    const [defaultUnit, setDefaultUnit] = useState(defaults?.default_unit ?? "");


    const handleSubmit = async e => {
        e.preventDefault();

        let error = await submit({
            name,
            search, density: density == 0 ? null : density,
            default_unit: defaultUnit == "" ? null : Number(defaultUnit)
        });

        if (error == null) {
            close();
        } else {
            alert(error);
        }
    }

    return (
        <div className='ingredient-modal'>

            <form onSubmit={handleSubmit}>
                <div className='ingredient-modal-inputs'>
                    <div>
                        <label htmlFor="name"><b>Name </b></label>
                        <input
                            title="Name of the ingredient. Anything after a colon will be ignored in the name but will still be used for search purposes. For example, you could enter, name:alternative name"
                            type="text"
                            placeholder="name"
                            name="name"
                            onChange={e => {
                                setName(e.target.value.split(":")[0]);
                                setSearch(e.target.value);
                            }}
                            value={search}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="density"><b>Density</b></label>
                        <input
                            type="number"
                            placeholder="density"
                            name="density"
                            onChange={e => setDensity(Number(e.target.value))}
                            value={density == 0 ? "" : density}
                            step="0.01"
                            min="0"
                            max="2.0"

                        />
                    </div>

                    <div>

                        <label htmlFor="type"><b>Default Unit</b></label>
                        <select name="type" onChange={e => setDefaultUnit(e.target.value)} value={defaultUnit}>
                            <option value="">Count</option>
                            {units.map(unit => (<option key={unit.id} value={unit.id}>{unit.unit.name}</option>))}
                        </select>
                    </div>
                </div>
                <div className='ingredient-modal-confirmation'>
                    <button type="submit">{confirmText}</button>
                    <button onClick={() => close()}>Cancel</button>
                </div>
            </form>
        </div>
    )
};

export default IngredientModal;


