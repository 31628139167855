/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Recipe } from '../models/Recipe';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecipeService {

    /**
     * Create a new recipe
     * @param requestBody Recipe to be created
     * @returns any Recipe successfully created
     * @throws ApiError
     */
    public static postApiRecipe(
        requestBody: Recipe,
    ): CancelablePromise<{
        /**
         * ID the newly created recipe
         */
        id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/recipe',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Returns all recipes
     * @returns any An array of all available recipes
     * @throws ApiError
     */
    public static getApiRecipeAll(): CancelablePromise<Array<{
        /**
         * unique id of the given recipe
         */
        id?: number;
        /**
         * name of the recipe
         */
        name?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/recipe/all',
        });
    }

    /**
     * Returns recipes which match with the query string
     * @param q Query string
     * @returns any An array of matching recipes
     * @throws ApiError
     */
    public static getApiRecipeSearch(
        q: string,
    ): CancelablePromise<Array<{
        /**
         * unique id of the given recipe
         */
        id?: number;
        /**
         * name of the recipe
         */
        name?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/recipe/search',
            query: {
                'q': q,
            },
        });
    }

    /**
     * Get details of a recipe
     * @param id recipe id
     * @returns Recipe recipe details
     * @throws ApiError
     */
    public static getApiRecipe(
        id: number,
    ): CancelablePromise<Recipe> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/recipe/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a recipe
     * @param id recipe id
     * @param requestBody recipe to be updated
     * @returns any Recipe successfully updated
     * @throws ApiError
     */
    public static putApiRecipe(
        id: number,
        requestBody: Recipe,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/recipe/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete a recipe
     * @param id recipe id
     * @returns any Recipe successfully deleted
     * @throws ApiError
     */
    public static deleteApiRecipe(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/recipe/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
