import './Ingredients.css'

import { useState, useEffect } from 'react';
import { IngredientService } from '../services/openapi';

import LoggedIn from '../components/LoggedIn'
import Popup from 'reactjs-popup';
import IngredientModal from "../components/IngredientModal";
import ConfirmModal from '../components/ConfirmModal';
import { useUnits } from '../scripts/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/fontawesome-free-solid'

function Item({ item, refresh }) {
    const units = useUnits(0);
    let defaultUnit = item.ingredient.default_unit;

    
    let defaultUnitString = "Count";
    if (units != null && defaultUnit != null) {
        defaultUnitString = units.find(u => u.id == defaultUnit)?.unit?.name ?? defaultUnitString;
    }

    return (
        <div className="ingredient-item">
            <span className="ingredient-name">{item.ingredient.name}</span>
            <span className="ingredient-density">{item.ingredient.density == null ? "" : item.ingredient.density + "g/mL"}</span>
            <div className="ingredient-item-controls">
                <LoggedIn>
                    <Popup trigger={<button><FontAwesomeIcon icon={faEdit} /></button>} modal nested>
                        {close => (<IngredientModal
                            confirmText="Update"
                            defaults={item.ingredient}
                            submit={(data) => {
                                IngredientService.putApiIngredient(item.id, data)
                                    .then(_ => refresh())
                                    .catch(error => alert("Failed to update ingredient"))
                            }}
                            close={close} />)}
                    </Popup>
                    <Popup trigger={<button><FontAwesomeIcon icon={faTrash} /></button>} modal nested>
                        {close => (<ConfirmModal
                            text="Are you sure you want to delete this entry?"
                            submit={() => {
                                IngredientService.deleteApiIngredient(item.id)
                                    .then(_ => refresh())
                                    .catch(error => alert("Failed to delete ingredient"))
                            }}
                            close={close} />)}
                    </Popup>
                </LoggedIn>
            </div>
        </div>
    );
}


function useIngredients(refreshKey) {
    const [ingredients, setIngredients] = useState([]);

    useEffect(() => {
        let ignore = false;
        IngredientService.getApiIngredientAll().then(result => {
            if (!ignore) {
                setIngredients(result);
            }
        }).catch(_ => alert("Failed to get ingredients"));
        return () => {
            ignore = true;
        };
    }, [refreshKey]);
    
    return ingredients;
}

function Ingredients() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const refresh = () => setRefreshKey(current => current + 1);

    const ingredients = useIngredients(refreshKey)
        .filter(ingredient => ingredient.ingredient.search.includes(searchQuery))
        .sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    
    let i = 0;
    return (
        <div className="ingredients-page">
            <div className="ingredient-header">
                <h1>Ingredients</h1>
                Search: <input type="text" onChange={e => setSearchQuery(e.target.value)}></input>
            </div>
            
            {ingredients.map(item => <Item key={i++} item={item} refresh={refresh} />)}
            <LoggedIn>

                <Popup trigger={<button> Add New </button>} modal nested>
                    {close => (<IngredientModal
                        confirmText="Add"
                        submit={(data) => {
                            IngredientService.postApiIngredient(data)
                                .then(_ => refresh())
                                .catch(e => "Failed to create ingredient");
                        }}
                        close={close} />)}
                </Popup>

            </LoggedIn>


        </div>
    )
}

export default Ingredients;
