import './Units.css';

import { useState, useEffect } from 'react';
import { UnitService } from '../services/openapi';
import { useUnits } from '../scripts/hooks';

import LoggedIn from '../components/LoggedIn'
import Popup from 'reactjs-popup';
import UnitModal from '../components/UnitModal';
import ConfirmModal from '../components/ConfirmModal';


function Item({ item, refresh }) {
    return (
        <>
            1 {item.unit.name} = {item.unit.multiplier} {item.unit.type == "volume" ? "mL" : "g"}
            <LoggedIn>
                <Popup trigger={<button> Edit </button>} modal nested>
                    {close => (<UnitModal 
                        confirmText="Update"
                        defaults={item.unit}
                        submit={(data) => {
                            UnitService.putApiUnit(item.id, data)
                                .then(_ => refresh())
                                .catch(error => alert("Failed to update unit"))
                        }} 
                        close={close} />)}
                </Popup>
                <Popup trigger={<button> Delete </button>} modal nested>
                    {close => (<ConfirmModal
                        text="Are you sure you want to delete this entry?"
                        submit={() => {
                            UnitService.deleteApiUnit(item.id)
                                .then(_ => refresh())
                                .catch(error => alert("Failed to delete"))
                        }}
                        close={close} />)}
                </Popup>
            </LoggedIn>
        </>
    );
}




function Units() {
    const [refreshKey, setRefreshKey] = useState(0);
    const refresh = () => setRefreshKey(current => current + 1);

    const units = useUnits(refreshKey);
    
    let i = 0;
    return (
        <>
            <ul>
                {units.map(item => <li key={i++}><Item item={item} refresh={refresh} /></li>)}
                <LoggedIn>
                    <li key={i++}>
                        <Popup trigger={<button> Add New </button>} modal nested>
                            {close => (<UnitModal 
                                confirmText="Add"
                                submit={(data) => {
                                    UnitService.postApiUnit(data)
                                        .then(_ => refresh())
                                        .catch(e => "Failed to create unit");
                                }} 
                                close={close} />)}
                        </Popup>
                    </li>
                </LoggedIn>
            </ul>

        </>
    )
}

export default Units;
