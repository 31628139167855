import './SignIn.css';


import { useState } from 'react';

import { UserContext } from '../App';
import { useContext } from 'react';

import LoggedIn from '../components/LoggedIn'
import LoggedOut from '../components/LoggedOut'

import { setCrendentials } from '../scripts/credentials';
import { useNavigate } from 'react-router-dom';

function SignIn() {
    let userContext = useContext(UserContext);
    let navigate = useNavigate();

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [warningVisible, setWarningVisible] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        const success = await setCrendentials(userContext, {
            username,
            password
        });

        if (success) {
            navigate('/recipes');
        }else{
            setWarningVisible(true);
        }
    }

    return (
        <div className="login">
            <div className="login-container">

                <form onSubmit={handleSubmit}>
                    <label htmlFor="username"><b>Username</b></label>
                    <input
                        type="text"
                        placeholder="Enter Username"
                        name="username"
                        onChange={e => setUsername(e.target.value)}
                        required
                    />

                    <label htmlFor="password"><b>Password</b></label>
                    <input
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        required
                    />

                    <div className={warningVisible ? "warning-enabled" : "warning-disabled"}>
                        Incorrect Password
                    </div>

                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
}

export default SignIn;
