import './RecipeModal.css';

import { useState } from 'react';
import Select from 'react-select'
import { useUnits, useIngredients, useRecipes } from '../scripts/hooks';
import IngredientDropDown from './IngredientDropDown';




const RecipeModal = ({ confirmText, submit, close, defaults }) => {
    const allUnits = useUnits(0).map(item => { return { label: item.unit.name, value: item }; });
    const allIngredients = useIngredients(0).map(item => { return { label: item.ingredient.name, value: item }; });
    const allRecipes = useRecipes(0).map(item => { return { label: item.name, value: item.id } });

    const [name, setName] = useState(defaults?.name ?? "");
    const [ingredients, setIngredients] = useState(defaults?.ingredients ?? []);
    const [subassemblies, setSubassemblies] = useState(defaults?.subassemblies ?? []);
    const [instructions, setInstructions] = useState(defaults?.instructions ?? []);


    const handleSubmit = async e => {
        e.preventDefault();

        let error = await submit({
            name,
            ingredients,
            subassemblies,
            instructions
        });

        console.log(error);

        if (error == null) {
            close();
        } else {
            alert(error);
        }
    }

    const addIngredient = () => {
        setIngredients(c => {
            return c.concat([{
                ingredient_id: -1,
                quantity: 0,
                unit_id: -1
            }]);
        });
    }

    const addSubassembly = () => {
        setSubassemblies(c => {
            return c.concat([{
                recipe_id: -1,
                quantity: 0,
            }]);
        });
    }

    const addInstruction = () => {
        setInstructions(c => {
            return c.concat([""]);
        });
    }

    const deleteIngredient = (index) => {
        setIngredients(c => {
            return c.filter((value, arrIndex) => index !== arrIndex);
        });
    }

    const deleteSubassembly = (index) => {
        setSubassemblies(c => {
            return c.filter((value, arrIndex) => index !== arrIndex);
        });
    }

    const deleteInstruction = (index) => {
        setInstructions(c => {
            return c.filter((value, arrIndex) => index !== arrIndex);
        });
    }

    return (
        <div className='recipe-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="recipe-modal-line">
                        <label htmlFor="name"><b>Name</b></label>
                        <input
                            className="grow"
                            type="text"
                            placeholder="name"
                            name="name"
                            onChange={e => setName(e.target.value)}
                            value={name}
                            required
                        />
                    </div>

                    <h3>Ingredients</h3>

                    {ingredients.map((item, index) => {
                        return (
                            <div className="recipe-modal-line" key={index}>
                                <Select
                                    className="grow"
                                    options={allIngredients}
                                    onChange={e => {
                                        setIngredients(c => {
                                            let result = c.slice();
                                            result[index].ingredient_id = e.value.id;
                                            return result;
                                        });
                                    }}
                                    value={allIngredients.filter(i => i.value.id == item.ingredient_id)[0] ?? ""}
                                />

                                <input
                                    type="number"
                                    placeholder="quantity"
                                    name="quantity"
                                    onChange={e => {
                                        setIngredients(c => {
                                            let result = c.slice();
                                            result[index].quantity = Number(e.target.value);
                                            return result;
                                        });
                                    }}
                                    value={item.quantity == 0 ? "" : item.quantity}
                                    step="0.01"
                                    min="0"
                                />

                                <Select
                                    options={allUnits}
                                    onChange={e => {
                                        setIngredients(c => {
                                            let result = c.slice();
                                            result[index].unit_id = e.value.id;
                                            return result;
                                        });
                                    }}
                                    value={allUnits.filter(i => i.value.id == item.unit_id)[0] ?? ""}
                                />
                                <button type="button" onClick={() => deleteIngredient(index)}>Delete</button>
                            </div>
                        )
                    })}

                    <div className="recipe-modal-line">
                        <button className="add-button" type="button" onClick={addIngredient}>Add Ingredient</button>
                    </div>


                    <h3>Subassemblies</h3>

                    {subassemblies.map((item, index) => {
                        return (
                            <div className="recipe-modal-line" key={index}>
                                <Select
                                    className="grow"
                                    options={allRecipes}
                                    onChange={e => {
                                        setSubassemblies(c => {
                                            let result = c.slice();
                                            result[index].recipe_id = e.value;
                                            return result;
                                        });
                                    }}
                                    value={allRecipes.filter(i => i.value == item.recipe_id)[0] ?? ""}
                                />

                                <input
                                    type="number"
                                    placeholder="quantity"
                                    name="quantity"
                                    onChange={e => {
                                        setSubassemblies(c => {
                                            let result = c.slice();
                                            result[index].quantity = Number(e.target.value);
                                            return result;
                                        });
                                    }}
                                    value={item.quantity == 0 ? "" : item.quantity}
                                    step="0.01"
                                    min="0"
                                />

                                <button type="button" onClick={() => deleteSubassembly(index)}>Delete</button>
                            </div>
                        )
                    })}

                    <div className="recipe-modal-line">
                        <button className="add-button" type="button" onClick={addSubassembly}>Add Subassembly</button>
                    </div>

                    <h3>Instructions</h3>

                    {instructions.map((item, index) => {
                        return (
                            <div className="recipe-step" key={index}>
                                Step {index}
                                <div className="recipe-modal-line" key={index}>
                                    <textarea
                                        className='grow'
                                        rows="5"
                                        cols="60"
                                        name="text"
                                        onChange={e => {
                                            setInstructions(c => {
                                                let result = c.slice();
                                                result[index] = e.target.value;
                                                return result;
                                            });
                                        }}
                                        value={item} />

                                    <button type="button" onClick={() => deleteInstruction(index)}>Delete</button>
                                </div>
                            </div>)
                    })}

                    <div className="recipe-modal-line">
                        <button className="add-button" type="button" onClick={addInstruction}>Add Step</button>
                    </div>

                    <div className="recipe-modal-confirm">
                        <button type="submit">{confirmText}</button>
                        <button onClick={() => close()}>Cancel</button>
                    </div>

                    
                </form>

            </div>
        </div>
    )
};

export default RecipeModal;


