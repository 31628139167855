
import { UnitService, IngredientService, RecipeService } from '../services/openapi';
import { useState, useEffect } from 'react';

export function useData(api, defaultValue, refreshKey) {
    const [data, setData] = useState(defaultValue);

    useEffect(() => {
        let ignore = false;
        api().then(result => {
            if (!ignore) {
                setData(result);
            }
        }).catch(_ => alert("Failed to get data"));
        return () => {
            ignore = true;
        };
    }, [refreshKey]);

    return data;
}

export function useUnits(refreshKey) {
    return useData(UnitService.getApiUnitAll, [], refreshKey);
}

export function useIngredients(refreshKey) {
    return useData(IngredientService.getApiIngredientAll, [], refreshKey);
}

export function useRecipes(refreshKey) {
    return useData(RecipeService.getApiRecipeAll, [], refreshKey);
}


