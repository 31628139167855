import { OpenAPI } from '../services/openapi/core/OpenAPI'
import { UserService } from '../services/openapi';

export async function setCrendentials(userContext, credentials) {
    OpenAPI.USERNAME = credentials.username;
    OpenAPI.PASSWORD = credentials.password;

    let request = UserService.headApiUser();

    return request
        .then(e => {
            userContext.setUser(credentials.username);
            return true;
        })
        .catch(e => {
            signOut(userContext);
            return false;
        });
}

export function signOut(userContext) {
    userContext.setUser(null);
    OpenAPI.USERNAME = null;
    OpenAPI.PASSWORD = null;
}