import './Layout.css';
import { Outlet, Link } from "react-router-dom";
import LoggedIn from '../components/LoggedIn';
import LoggedOut from '../components/LoggedOut';
import SignOutButton from "../components/SignOutButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/fontawesome-free-solid'

function toggleMenu() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
}



const Layout = () => {

    return (
        <>
            <div className="topnav" id="myTopnav">
                <Link to="/recipes">Recipes</Link>
                <Link to="/ingredients">Ingredients</Link>
                <LoggedIn>
                    {/*<Link to="/units">Units</Link>*/}
                    <SignOutButton/>
                </LoggedIn>
                <LoggedOut><Link to="/signin">Sign In</Link></LoggedOut>
                <a className="icon" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </a>
            </div>

            <Outlet />
        </>
    )
};

export default Layout;
