import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { OpenAPI } from './services/openapi/core/OpenAPI'
import {createContext, useState} from 'react';

import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Units from "./pages/Units";
import Ingredients from "./pages/Ingredients";
import Recipes from "./pages/Recipes";
import RecipeDetails from "./pages/RecipeDetails";
import SignIn from "./pages/SignIn";


export const UserContext = createContext(null);

function App() {
  
  OpenAPI.BASE = window.location.origin;

  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{user, setUser}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="units" element={<Units />} />
            <Route path="ingredients" element={<Ingredients />} />
            <Route path="recipes" element={<Recipes />} />
            <Route path="recipes/:id" element={<RecipeDetails />} />
            <Route path="signin" element={<SignIn />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
