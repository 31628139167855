import {UserContext} from '../App';
import {useContext} from 'react';


const UserState = ({loggedIn, loggedOut}) => {
    let context = useContext(UserContext);

    if (context.user == null) {
        return (<>{loggedOut}</>);
    }else
    {
        return (<>{loggedIn}</>);
    }
};

export default UserState;
