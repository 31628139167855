import {useState} from 'react';

const UnitModal = ({confirmText, submit, close, defaults}) => {
    
    const [name, setName] = useState(defaults?.name ?? "");
    const [type, setType] = useState(defaults?.type ?? "weight");
    const [multiplier, setMultiplier] = useState(defaults?.multiplier ?? "");

    const handleSubmit = async e => {
        e.preventDefault();

        let error = await submit({name, type, multiplier});
        
        if (error == null) {
            close();
        } else {
            alert(error);
        }
    }

    return (
        <div className='modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name"><b>Name</b></label>
                    <input
                        type="text"
                        placeholder="name"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name}
                        required
                    />

                    <label htmlFor="type"><b>Type</b></label>
                    <select name="type" onChange={e => setType(e.target.value)} value={type}>
                        <option value="weight">Weight</option>
                        <option value="volume">Volume</option>
                    </select>

                    <label htmlFor="multiplier"><b>Multiplier</b></label>
                    <input
                        type="number"
                        placeholder="multiplier"
                        name="multiplier"
                        onChange={e => setMultiplier(Number(e.target.value))}
                        value={multiplier}
                        required
                    />

                    <button type="submit">{confirmText}</button>
                    <button onClick={() => close()}>Cancel</button>
                </form>
                
            </div>
        </div>
    )
};

export default UnitModal;


