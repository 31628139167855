import './RecipeDetails.css'

import { useParams, useNavigate, Link} from "react-router-dom";
import { useState, useEffect } from "react";
import Popup from 'reactjs-popup';

import { RecipeService } from "../services/openapi";
import LoggedIn from "../components/LoggedIn";
import RecipeModal from "../components/RecipeModal";
import ConfirmModal from "../components/ConfirmModal";

function useRecipe(id, refreshKey) {
    const [recipe, setRecipe] = useState(null);

    useEffect(() => {
        let ignore = false;
        RecipeService.getApiRecipe(id).then(result => {
            if (!ignore) {
                setRecipe(result);
            }
        }).catch(_ => alert("Failed to get recipes"));
        return () => {
            ignore = true;
        };
    }, [id, refreshKey]);

    return recipe;
}

function IngredientEntry({name, quantity, unit}) {
    return (
        <tr>
            <td className="ingredient-qty">
                {quantity} {unit}
            </td>
            <td className="ingredient-name">
                {name}
            </td>
        </tr>
    );
}

function SubassemblyEntry({id, name, quantity}) {
    return (
        <li>
            <Link className="recipe-subassembly" to={`./../${id}`}>
                {quantity} x {name}
            </Link>
        </li>
    );
}

function RecipeDetails() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [step, setStep] = useState(0);
    
    const navigate = useNavigate();
    const { id } = useParams();
    const recipe = useRecipe(id, refreshKey);

    const refresh = () => setRefreshKey(current => current + 1);

    const nextStep = () => {
        setStep(Math.min(step + 1, recipe.instructions.length-1));
    }

    const previousStep = () => {
        setStep(Math.max(step - 1, 0));
    }

    let hasInstructions = recipe != null && recipe.instructions.length > 0;
    let hasSubassembly = recipe != null && recipe.subassemblies.length > 0;

    if (recipe == null) {
        return (<><h1>Loading</h1></>)
    } else {
        return (
            <div className="recipe-details">
                <Link className="button-link" onClick={() => navigate(-1)}>Back</Link>
                <div className="recipe-ingredients">
                    <h2>Ingredients</h2>
                    <table>
                        <tbody>
                        {recipe.ingredients.map((item, index) => {
                            return (<IngredientEntry
                                key={index}
                                name={item.ingredient_name}
                                quantity={item.quantity}
                                unit={item.unit_name} />);
                        })}
                    </tbody></table>
                    {hasSubassembly ? <h2>Subassemblies</h2> : <></>}
                    <ul>
                        {recipe.subassemblies.map((item, index) => {
                            return (<SubassemblyEntry
                                key={index}
                                id={item.recipe_id}
                                name={item.recipe_name}
                                quantity={item.quantity} />);
                        })}
                    </ul>
                    <LoggedIn>
                        <Popup className="recipe-popup" trigger={<button> Edit </button>} modal nested>
                            {close => (<RecipeModal
                                confirmText="Update"
                                defaults={recipe}
                                submit={(data) => {
                                    RecipeService.putApiRecipe(id, data)
                                        .then(_ => refresh())
                                        .catch(error => alert("Failed to update ingredient"))
                                }}
                                close={close} />)}
                        </Popup>
                        <Popup trigger={<button> Delete </button>} modal nested>
                            {close => (<ConfirmModal
                                text="Are you sure you want to delete this entry?"
                                submit={() => {
                                    RecipeService.deleteApiRecipe(id)
                                        .then(_ => navigate('/recipes'))
                                        .catch(error => alert("Failed to delete ingredient"))
                                }}
                                close={close} />)}
                        </Popup>
                    </LoggedIn>
                </div>
                
                <div className="recipe-instructions">
                    <h1>{recipe.name}</h1>
                    
                    {hasInstructions ? 
                        (<>
                            <h2>Step {step + 1} of {recipe.instructions.length}</h2>
                            <p>{recipe.instructions[step]}</p>
                        </>) 
                        :
                        (
                            <p>This recipe has no instructions</p>
                        )
                    }
                </div>
                
                <div className={hasInstructions ? "recipe-controls" : "hidden"}>
                    <button onClick={previousStep}>Prev</button>
                    <button onClick={nextStep}>Next</button>
                </div>

            </div>
        )
    }
}

export default RecipeDetails;