import {UserContext} from '../App';
import {useContext} from 'react';
import { Link } from 'react-router-dom';

import { signOut } from '../scripts/credentials';

const SignOutButton = ({loggedIn, loggedOut}) => {
    let context = useContext(UserContext);
    return (<Link to={() => {}} onClick={e => signOut(context)}>Sign Out</Link>)
};

export default SignOutButton;
