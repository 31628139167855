import './Recipes.css';

import { useState, useEffect } from 'react';
import { RecipeService } from '../services/openapi';

import { Link } from 'react-router-dom';

import LoggedIn from '../components/LoggedIn'
import Popup from 'reactjs-popup';
import RecipeModal from "../components/RecipeModal";




function Item({ item }) {
    return (

        <Link to={`./${item.id}`}>
            <div className="recipe-tile">
                {item.name}
            </div>
        </Link>

    );
}


function useRecipes(refreshKey) {
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        let ignore = false;
        RecipeService.getApiRecipeAll().then(result => {
            if (!ignore) {
                setRecipes(result);
            }
        }).catch(_ => alert("Failed to get recipes"));
        return () => {
            ignore = true;
        };
    }, [refreshKey]);

    return recipes;
}

function Recipes() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const refresh = () => setRefreshKey(current => current + 1);

    const recipes = useRecipes(refreshKey)
        .filter(recipe => recipe.name.includes(searchQuery))
        .sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

    return (
        <>
            <div className="recipe-page">
                <div className="recipe-container">
                    <div className="recipe-header">
                        <h1>Recipes</h1>
                        Search: <input type="text" onChange={e => setSearchQuery(e.target.value)}></input>
                    </div>

                    <div className="recipe-list">
                        {recipes.map((item, index) => <Item key={index} item={item} refresh={refresh} />)}
                        <LoggedIn>
                        <Popup className="recipe-popup" trigger={<Link to={() => {}}> <div className="recipe-tile">Add New </div></Link>} modal nested>
                            {close => (<RecipeModal
                                confirmText="Add"
                                submit={(data) => {
                                    return RecipeService.postApiRecipe(data)
                                        .then(_ => refresh())
                                        .catch(e => "Failed to create recipe");
                                }}
                                close={close} />)}
                        </Popup>
                    </LoggedIn>
                    </div>
                    
                </div>
            </div>

        </>
    )
}

export default Recipes;
