/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Unit } from '../models/Unit';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UnitService {

    /**
     * Create a new unit
     * @param requestBody Unit to be updated
     * @returns any Unit successfully created
     * @throws ApiError
     */
    public static postApiUnit(
        requestBody: Unit,
    ): CancelablePromise<{
        /**
         * ID the newly created unit
         */
        id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/unit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Returns all units
     * @returns any An array of all available units
     * @throws ApiError
     */
    public static getApiUnitAll(): CancelablePromise<Array<{
        /**
         * unique id of the given unit
         */
        id?: number;
        unit?: Unit;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/unit/all',
        });
    }

    /**
     * Returns units which match with the query string
     * @param q Query string
     * @returns any An array of matching units
     * @throws ApiError
     */
    public static getApiUnitSearch(
        q: string,
    ): CancelablePromise<Array<{
        /**
         * unique id of the given unit
         */
        id?: number;
        unit?: Unit;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/unit/search',
            query: {
                'q': q,
            },
        });
    }

    /**
     * Update an unit
     * @param id unit id
     * @param requestBody Unit to be updated
     * @returns any Unit successfully updated
     * @throws ApiError
     */
    public static putApiUnit(
        id: number,
        requestBody: Unit,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/unit/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Creation failed due to invalid parameters`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete a unit
     * @param id unit id
     * @returns any Unit successfully deleted
     * @throws ApiError
     */
    public static deleteApiUnit(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/unit/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
