/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Unit = {
    /**
     * name of the unit
     */
    name: string;
    /**
     * whether the unit is of weight or of volume.
     */
    type: Unit.type;
    /**
     * number of grams or milliliters the unit represents
     */
    multiplier: number;
};

export namespace Unit {

    /**
     * whether the unit is of weight or of volume.
     */
    export enum type {
        WEIGHT = 'weight',
        VOLUME = 'volume',
    }


}

